import { HtmlHTMLAttributes, useMemo } from 'react';

// Styles
import { Wrap, Image, TopWrap, BottomWrap, WebSiteLink, SHPFullLogo } from './styles';

// Config
import { SHP_WEBSITE_NAME } from 'config';

// Types
import type { QrCodeDataResponse } from '../../api/apiGetQrCodes';
export interface QrCodeProps extends HtmlHTMLAttributes<HTMLDivElement> {
  data?: Partial<QrCodeDataResponse>;
  showId?: boolean;
  showCategory?: boolean;
  dashedBorder?: boolean;
  removable?: boolean;
  xs?: number;
}
export const QrCode = ({
  data = {},
  showCategory = true,
  showId = true,
  dashedBorder = true,
  removable = true,
  xs,
  ...props
}: QrCodeProps) => {
  const {
    id = '',
    link = '',
    category = ''
  } = data;
  const webSiteLink = useMemo(() => {
    const {
      hostname
    } = global?.window?.location || {};
    return hostname === 'localhost' ? 'LOCALHOST' : SHP_WEBSITE_NAME;
  }, []);
  return <Wrap $dashedBorder={dashedBorder} $category={category as any} $xs={xs} $removable={removable} {...props}>
      {showCategory && <TopWrap>
          <SHPFullLogo />
        </TopWrap>}
      {/* @ts-ignore */}
      <Image src={link} crossorigin="anonymous" alt="QR Code" />
      {showId && <BottomWrap>
          <span>{id}</span>
          <WebSiteLink>{webSiteLink}</WebSiteLink>
        </BottomWrap>}
    </Wrap>;
};